:root{
    --temp:rgba(220, 80, 80, 0.3);
    --temp-active:rgba(220, 80, 120, 0.4)
}

@media only screen and (min-width: 1024px) {
    .foundation {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        .watermark {
            // position: absolute;
            // top: 0;
            // left: 0;
            // top: 50%;
            // transform: translateY(-50%);
            background: url("../../img/watermark@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            height:100vh;
            width: 100vw;
            max-height: 300px;
            z-index: 0;
        }
    
        .controller {
            position: relative;
            max-width: 800px;
            z-index: 1;
            #holder {
                width: 100%;
            }
    
            .light-control-button {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                // transform: translateY(-50%);
                #add{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.375%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 10.875%;
                    border: none;
                }
                #add:active{
                    background:var(--temp-active);
                }
                #minus{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 33.125%;
                    border: none;
                }
                #minus:active{
                    background:var(--temp-active);
                }
                #left{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 55.5%;
                    border: none;
                }
                #left:active{
                    background:var(--temp-active);
                }
                #right{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.625%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 77.875%;
                    border: none;
                }
                #right:active{
                    background:var(--temp-active);
                }
            }
        }   
    }
}

@media only screen and (min-width:500px) and (max-width:1024px){
    .foundation {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 84.7666vw;
        max-width: 793px;
        // max-width: 569.99px;
        .watermark {
            position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            background: url("../../img/watermark@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            height:100vh;
            width: 100vw;
            max-height: 300px;
            z-index: 0;
        }
    
        .controller {
            position: relative;
            z-index: 1;
            #holder {
                width: 100%;
            }
    
            .light-control-button {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                // transform: translateY(-50%);
                #add{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.375%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 10.875%;
                    border: none;
                }
                #add:active{
                    background:var(--temp-active);
                }
                #minus{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 33.125%;
                    border: none;
                }
                #minus:active{
                    background:var(--temp-active);
                }
                #left{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 55.5%;
                    border: none;
                }
                #left:active{
                    background:var(--temp-active);
                }
                #right{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.625%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 77.875%;
                    border: none;
                }
                #right:active{
                    background:var(--temp-active);
                }
            }
        }   
    }
}

@media only screen and (min-width:0px) and (max-width:500px){
    .foundation {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 84.7666vw;
        max-width: 569.99px;
    
        .watermark {
            position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            background: url("../../img/watermark@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            height:100vh;
            width: 100vw;
            max-height: 300px;
            z-index: 0;
        }
    
        .controller {
            position: relative;
            z-index: 1;
            #holder {
                width: 100%;
            }
    
            .light-control-button {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                // transform: translateY(-50%);
                #add{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.375%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 10.875%;
                    border: none;
                }
                #add:active{
                    background:var(--temp-active);
                }
                #minus{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 10.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 33.125%;
                    border: none;
                }
                #minus:active{
                    background:var(--temp-active);
                }
                #left{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.5%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 55.5%;
                    border: none;
                }
                #left:active{
                    background:var(--temp-active);
                }
                #right{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-60%);
                    width: 9.625%;
                    height: 43.205%;
                    background: var(--temp);
                    margin-left: 77.875%;
                    border: none;
                }
                #right:active{
                    background:var(--temp-active);
                }
            }
        }   
    }
}




