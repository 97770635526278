@media only screen and (min-width: 1024px){
    
}

@media only screen and (min-width: 500px) and (max-width: 1024px){

}

@media only screen and (min-width: 0px) and ( max-width: 1024px){

}

#main-container {
    display: block;
}

#context {
    width: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coordinate {
    color: white;
}