
@media only screen and (min-width: 1000px) {
    .header {
        position: absolute;
        left: 2.5rem;
        top: -5px;
        background-image: url("../../img/sandpock-logo@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 10.5625rem;
        height: 2.6875rem;
        z-index: 1;
    }
}
@media only screen and (min-width: 500px) and (max-width: 1000px) {
    .header {
        position: absolute;
        left: 2.5rem;
        top: -5px;
        background-image: url("../../img/sandpock-logo@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 10.5625rem;
        height: 2.6875rem;
        z-index: 1;
    }
}
@media only screen and (min-width: 0px) and (max-width: 500px){
    .header {
        background-image: url("../../img/sandpock-logo@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 10.5625rem;
        height: 2.6875rem;
        display: none;
        // margin-top: 159px;
    }
}