@media only screen and (min-width:1024px){
    .language-switch {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
        #english {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width:45.62px;
            height: 18.31px;
            #english-img{
                width: 33.62px;
                height: 16.32px;
            }
        }

        p{
            color: #5445AE;
            font-weight: 700;
            margin-left: 8px;
            margin-right: 8px;
        }

        #chinese {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width:45.62px;
            height: 18.31px;
            #chinese-img{
                width: 33.63px;
                height: 16.32px;
            }
        }
    }
}

@media only screen and (min-width:500px) and (max-width:1024px){
    .language-switch {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        #chinese {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            #chinese-img{
                width: 33.63px;
                height: 16.32px;
            }
        }
        p{
            color: #5445AE;
            font-weight: 700;
            margin-left: 8px;
            margin-right: 8px;
        }
        #english {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            #english-img{
                width: 33.62px;
                height: 16.32px;
            }
        }
    }
}

@media only screen and (min-width:0px) and (max-width:500px){
    .language-switch {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        #chinese {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            #chinese-img{
                width: 33.63px;
                height: 16.32px;
            }
        }
        #english {
            background: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            #english-img{
                width: 33.62px;
                height: 16.32px;
            }
        }
        p{
            color: #5445AE;
            font-weight: 700;
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}

