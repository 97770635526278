
@media only screen and (min-width: 1025px){
    .info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 74.218vw;
        margin: 80px 0 90px 0;
        // width: 52%;
    }
    
    .info-content{
        color: #D9D9D9;
        font-size: 16px;
        max-width: 760px;
        min-width: 330.59px;
        line-height: 28.8px;
        font-weight: 400;
        text-align: left;
    }
}

@media only screen and (min-width: 500px) and (max-width: 1025px){
    .info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 80px 0 90px 0;
        // max-width: 500px;
        width: 74.218vw;
    }
    
    .info-content{
        color: #D9D9D9;
        font-size: 16px;
        max-width: 760px;
        min-width: 330.59px;
        line-height: 28.8px;
        font-weight: 400;
        text-align: left;
    }
}

@media only screen and (min-width: 0px) and (max-width: 500px){
    .info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 80px 0 90px 0;
        // max-width: 500px;
        width: 74.218vw;
    }
    
    .info-content{
        color: #D9D9D9;
        font-size: 16px;
        max-width: 760px;
        min-width: 330.59px;
        line-height: 28.8px;
        font-weight: 400;
        text-align: left;
    }
}

