

@media only screen and (min-width:1024px){
    #mission {
        position: absolute;
        left: 0;
        width: 100%;
        // height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display:none;
    }
}
@media only screen and (min-width:500px) and (max-width:1024px){
    #mission {
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }
}
@media only screen and (min-width:0px) and (max-width:500px){
    #mission {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }
}