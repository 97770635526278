
@media only screen and (min-width: 1024px) {
    .nav-container{
        .Nav {
            position: relative;
            background: black;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 1.75rem 2.5rem 0 2.5rem;
            visibility: visible;
            .switch-button {
                position:relative;
                background: #242424;
                border-radius: 100px;
                width: 245px;
                height: 30px;
                border: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                #pass-page {
                    position: absolute;
                    left: 4px;
                    border: none;
                    width: 50%;
                    height: 90%;
                    color: #D9D9D9;
                    font-size: 16px;
                    font-weight: 700;
                    background: #5445AE;
                    border-radius: 100px;
                    height: 24px;
                    width: 118px;
                }
                #mission-page {
                    /* background: transparent; */
                    position: absolute;
                    right: 4px;
                    border: none;
                    width: 50%;
                    height: 90%;
                    color: #D9D9D9;
                    font-size: 16px;
                    font-weight: 700;
                    background: #242424;
                    border-radius: 100px;
                    height: 24px;
                    width: 118px;
                }
            }
            .section-connect-switch {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .language-switch {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-left: 25px;
                }
            
                .language-switch > p{
                    color: #5445AE;
                    font-weight: 700;
                    margin-left: 8px;
                    margin-right: 8px;
                }
            
                .connect-wallet{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: #242424;
                    border-radius: 30px;
                    color: #D9D9D9;
                    height: 30px;
                    width: 114px;
                    text-align: center;
                    /* height: 30px; */
                }
                        
                #metamask {
                    width:24px;
                    height: 24px;
                    margin-right: 0em;
                }
            
                #connect-wallet{
                    background: transparent;
                    border: none;
                    height: 30px;
                    width: 114px;
                    color: #D9D9D9;
                    font-weight: 700;
                }
            }
        }
        .mini-nav {
            position: relative;
            visibility: hidden;
            top: 10px;
            right: 20px;
            #menu {
                padding: 0 0;
                content: "";
                width: 32px;
                height: 21px;
                background: transparent;
                border: none;
                .menu-bar {
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: relative;
                    transition:background-color .01s .5s;
                }
                .menu-bar::after{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    bottom: -6px;
                }
                .menu-bar::before{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    top: -6px;
                }
            }
        }
        #menu-popup{
            display: none;
            visibility: hidden;
        }
    }
}

@media only screen and (min-width: 500px) and (max-width:1024px) {
    .nav-container{
        .Nav {
            position: relative;
            background: black;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 1.75rem 2.5rem 0 2.5rem;
            .switch-button {
                position:relative;
                background: #242424;
                border-radius: 100px;
                width: 245px;
                height: 30px;
                border: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                #pass-page {
                    position: absolute;
                    left: 4px;
                    border: none;
                    width: 50%;
                    height: 90%;
                    color: #D9D9D9;
                    font-size: 16px;
                    font-weight: 700;
                    background: #5445AE;
                    border-radius: 100px;
                    height: 24px;
                    width: 118px;
                }
                #mission-page {
                    /* background: transparent; */
                    position: absolute;
                    right: 4px;
                    border: none;
                    width: 50%;
                    height: 90%;
                    color: #D9D9D9;
                    font-size: 16px;
                    font-weight: 700;
                    background: #242424;
                    border-radius: 100px;
                    height: 24px;
                    width: 118px;
                }
            }
            .section-connect-switch {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .language-switch {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-left: 25px;
                }
            
                .language-switch > p{
                    color: #5445AE;
                    font-weight: 700;
                    margin-left: 8px;
                    margin-right: 8px;
                }
            
                .connect-wallet{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: #242424;
                    border-radius: 30px;
                    color: #D9D9D9;
                    height: 30px;
                    width: 114px;
                    text-align: center;
                    /* height: 30px; */
                }
                        
                #metamask {
                    width:24px;
                    height: 24px;
                    margin-right: 0em;
                }
            
                #connect-wallet{
                    background: transparent;
                    border: none;
                    height: 30px;
                    width: 114px;
                    color: #D9D9D9;
                    font-weight: 700;
                }
            
                #chinese {
                    background: transparent;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            
                #chinese-img{
                    width: 33.63px;
                    height: 16.32px;
                }
            
                #english {
                    background: transparent;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    #english-img{
                        width: 33.62px;
                        height: 16.32px;
                    }
                }
            }
        }
        .mini-nav {
            position: relative;
            visibility: hidden;
            #menu {
                padding: 0 0;
                content: "";
                width: 32px;
                height: 21px;
                background: transparent;
                border: none;
                .menu-bar {
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: relative;
                    transition:background-color .01s .5s;
                }
                .menu-bar::after{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    bottom: -6px;
                }
                .menu-bar::before{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    top: -6px;
                }
            }
        }
        #menu-popup{
            display: none;
            visibility: hidden;
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 500px){
    .nav-container{
        position: relative;
        width: 100%;
        height: 100%;
        .Nav {
            display: none;
            visibility: hidden;
        }
        .mini-nav {
            position: relative;
            visibility: visible;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 26px 7.6923vw 0px 7.6923vw;
            z-index: 4;
            // right: 20px;
            .mini-nav-logo{
                content: "";
                width: 39px;
                height: 31px;
                background: url("../../img/sandpock-logo-mini@2x.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
            #menu {
                padding: 0 0;
                content: "";
                width: 32px;
                height: 21px;
                background: transparent;
                border: none;
                .menu-bar {
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: relative;
                    transition:background-color .01s .5s;
                }
                .menu-bar::after{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    bottom: -6px;
                }
                .menu-bar::before{
                    content:"";
                    width: 100%;
                    height: 3px;
                    border-radius:3px;
                    background-color: #D9D9D9;
                    position: absolute;
                    transform: translateX(-50%);
                    top: -6px;
                }
            }
        }
        #menu-popup {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: none;
            visibility: visible;
            .menu-popup-container{
                position: fixed;
                width: 100%;
                height: 100%;
                background: black;
                .content{
                    background: black;
                    display: flex;
                    flex-direction: column;
                    justify-content:center;
                    align-items: center;
                    margin: 210px 30px 0 30px;
                    gap: 40px;
                }
                hr {
                    width: 100%;
                    border: #D9D9D9 solid 0.5px;
                }
            }
        }
    }
}
    

    