@media only screen and (min-width:1024px){
    .section-three {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 26.25vw;
            min-width: 310px;
        }
        .stagethree-content {
            margin-top: 20px;
            font-size: 16px;
            line-height: 19.36px;
            font-weight: 400;
            text-align: center;
            color: #D9D9D9;
        }
        
        #invite-discord {
            margin-top: 25px;
            background: #5445AE;
            width: 100px;
            height: 34px;
            border-radius: 30px;
            border: none;
            color: #D9D9D9;
        } 
    }
    
        
}

@media only screen and (min-width:500px) and (max-width:1024px){
    .section-three {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            min-width: 310px;
            width:26.25px;
        }
        .stagethree-content {
            margin-top: 20px;
            font-size: 16px;
            line-height: 19.36px;
            font-weight: 400;
            text-align: center;
            color: #D9D9D9;
        }
        #invite-discord {
            margin-top: 25px;
            background: #5445AE;
            width: 100px;
            height: 34px;
            border-radius: 30px;
            border: none;
            color: #D9D9D9;
        } 
    }
}

@media only screen and (min-width:0px) and (max-width:500px){
    .section-three {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 26.25px;
            min-width: 310px;
        }
        .stagethree-content {
            margin-top: 20px;
            font-size: 16px;
            line-height: 19.36px;
            font-weight: 400;
            text-align: center;
            color: #D9D9D9;
        }
        
        #invite-discord {
            margin-top: 25px;
            background: #5445AE;
            width: 100px;
            height: 34px;
            border-radius: 30px;
            border: none;
            color: #D9D9D9;
        }
    }
    
    
}

