@media only screen and (min-width:1024px){
    .nav-footer {
        display: none;
    }
}

@media only screen and (min-width:500px) and (max-width:1024px){
    .nav-footer {
        display: none;
        // margin-top: 159px;
    }
}

@media only screen and (min-width:0px) and (max-width:500px){
    .nav-footer {
        background-image: url("../../img/sandpock-logo@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 10.5625rem;
        height: 2.6875rem;
        margin-top: 159px;
    }
}