html,body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}
*{
    margin:0px;
    box-sizing: border-box;
}

header {
    text-align: center;
}

main {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

a {
    background: purple;
    color: aqua;
}