:root{
    --text:rgba(217, 217, 217, 0.25);
}

@media only screen and (min-width:500px){
    .footer{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 12px;
        margin-left: 50px;
        margin-right: 50px;
        gap: 20px;
        #first-stage-info{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            display: block;
            visibility: visible;
        }
        #second-stage-info{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            margin-bottom: 100px;
            display: block;
            visibility: visible;
        }
        #first-stage-info-mini{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            visibility: hidden;
            display: none;
        }
        #second-stage-info-mini{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            visibility: hidden;
            display: none;
        }
    }
}

@media only screen and (min-width:0px) and (max-width:500px){
    .footer{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 36px;

        gap: 20px;
        #first-stage-info{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            display: none;
            visibility: hidden;
        }
        #second-stage-info{
            width: 183px;
            height: 34px;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            display: none;
            visibility: hidden;
        }
        #first-stage-info-mini{
            width: 97px;
            height: 34px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1em;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            visibility: visible;
            display: block;
        }
        #second-stage-info-mini{
            width: 97px;
            height: 34px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1em;
            color: var(--text);
            background: transparent;
            border: 1px solid var(--text);
            border-radius: 100px;
            visibility: visible;
            display: block;
        }
    }
}

