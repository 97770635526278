@media only screen and (min-width: 1024px){
    .lightbulb-container {
        margin-top: 112px;
        max-width: 800px;
        z-index: 1;
        .light {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 6.5%;
            z-index: 1;
        
            #bulb-00 {
                width: 15.75%;
            }
        
            #bulb-01 {
                width: 15.75%;
            }
        
            #bulb-02 {
                width: 15.75%;
            }
        
            #bulb-03 {
                width: 15.75%;
            }
        
            .info-test {
                color: white;
            }
        }
    }    
}

@media only screen and (min-width: 500px) and (max-width:1024px){
    .lightbulb-container {
        width: 84.7666vw;
        max-width: 793px;
        margin-top: 112px;
        z-index: 1;
        .light {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 6.5%;
            z-index: 1;
        
            #bulb-00 {
                width: 15.75%;
            }
        
            #bulb-01 {
                width: 15.75%;
            }
        
            #bulb-02 {
                width: 15.75%;
            }
        
            #bulb-03 {
                width: 15.75%;
            }
        
            .info-test {
                color: white;
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width:500px){
    .lightbulb-container {
        width: 84.7666vw;
        max-width: 569.99px;
        margin-top: 112px;
        z-index: 1;
        .light {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 6.5%;
            z-index: 1;
        
            #bulb-00 {
                width: 15.75%;
            }
        
            #bulb-01 {
                width: 15.75%;
            }
        
            #bulb-02 {
                width: 15.75%;
            }
        
            #bulb-03 {
                width: 15.75%;
            }
        
            .info-test {
                color: white;
            }
        }
    }
}


