@media only screen and (min-width:1024px){
    .switch-button {
        position:relative;
        background: #242424;
        border-radius: 100px;
        width: 245px;
        height: 30px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        #pass-page {
            position: absolute;
            left: 4px;
            border: none;
            width: 50%;
            height: 90%;
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: #5445AE;
            border-radius: 100px;
            height: 24px;
            width: 118px;
        }
        #mission-page {
            /* background: transparent; */
            position: absolute;
            right: 4px;
            border: none;
            width: 50%;
            height: 90%;
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: #242424;
            border-radius: 100px;
            height: 24px;
            width: 118px;
        }
    }   
}

@media only screen and (min-width:500px) and (max-width:1024){
    .switch-button {
        position:relative;
        background: #242424;
        border-radius: 100px;
        width: 245px;
        height: 30px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        #pass-page {
            position: absolute;
            left: 4px;
            border: none;
            width: 50%;
            height: 90%;
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: #5445AE;
            border-radius: 100px;
            height: 24px;
            width: 118px;
        }
        #mission-page {
            /* background: transparent; */
            position: absolute;
            right: 4px;
            border: none;
            width: 50%;
            height: 90%;
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: #242424;
            border-radius: 100px;
            height: 24px;
            width: 118px;
        }
    }    
}

@media only screen and (min-width:0px) and (max-width:500px){
    .switch-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        #pass-page {
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: transparent;
            // background: #5445AE;
            // border-radius: 100px;
            border: none;
            height: 24px;
            width: 118px;
        }
        #mission-page {
            /* background: transparent; */
            color: #D9D9D9;
            font-size: 16px;
            font-weight: 700;
            background: transparent;
            // background: #242424;
            // border-radius: 100px;
            border: none;
            height: 24px;
            width: 118px;
        }
    }   
}
    