
.mint {
    display:flex;
    z-index: 1;
}

#mint-button {
    margin-top: 26.5px;
    width: 200.98px;
    height: 56px;
    background: url("../../img/mint-cht-enable-default@2x.png");
    background-repeat: no-repeat;
    background-size: 200.98px 56px;
    border: none;
}

#mint-button:hover {
    margin-top: 26.5px;
    width: 200.98px;
    height: 56px;
    background: url("../../img/mint-cht-enable-hover@2x.png");
    background-repeat: no-repeat;
    background-size: 200.98px 56px;
    border: none;
}

/* #mint-pic {
    width: 200.98px;
    height: 56px;
} */