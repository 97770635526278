@media only screen and (min-width:1024px){
    
.connect-wallet{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #242424;
    border-radius: 30px;
    color: #D9D9D9;
    height: 30px;
    width: 114px;
    text-align: center;
    /* height: 30px; */
    #connect-wallet{
        background: transparent;
        border: none;
        height: 30px;
        width: 114px;
        color: #D9D9D9;
        font-weight: 700;
    }
}
#is-connect-wallet{
    width: 200px;
    height: 30px;
    background: #5445AE;
    border-radius: 30px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #D9D9D9;
    font-size: 16px;
    font-weight: 700;
    gap: 5px;
    #metamask {
        width:24px;
        height: 24px;
        margin-right: 0em;
    }    
}   
        



}

@media only screen and (min-width:500px) and (max-width:1024px){

    .connect-wallet{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #242424;
        border-radius: 30px;
        color: #D9D9D9;
        height: 30px;
        width: 114px;
        text-align: center;
        /* height: 30px; */
        #connect-wallet{
            background: transparent;
            border: none;
            height: 30px;
            width: 114px;
            color: #D9D9D9;
            font-weight: 700;
        } 
    }
            
    #is-connect-wallet{
        width: 200px;
        height: 30px;
        background: #5445AE;
        border-radius: 30px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #D9D9D9;
        font-size: 16px;
        font-weight: 700;
        gap: 5px;
        #metamask {
            width:24px;
            height: 24px;
            margin-right: 0em;
        }    
    }   
}

@media only screen and (min-width:0px) and (max-width:500px){

    .connect-wallet{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #D9D9D9;
        border-radius: 30px;
        color: black;
        height: 30px;
        width: 114px;
        text-align: center;
        /* height: 30px; */
        #connect-wallet{
            background: transparent;
            border: none;
            height: 30px;
            width: 114px;
            color: black;
            font-weight: 700;
        }  
    }
    #is-connect-wallet{
        width: 200px;
        height: 30px;
        background: #5445AE;
        border-radius: 30px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #D9D9D9;
        font-size: 16px;
        font-weight: 700;
        gap: 5px;
        #metamask {
            width:24px;
            height: 24px;
            margin-right: 0em;
        }    
    } 
}
