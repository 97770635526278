
.watermark {
    position: absolute;
    top: 43vh;
    background: url("../../img/watermark@2x.png");
    background-size: 107.638vw;
    // background-size: contain;
    background-repeat: no-repeat;
    height: 107.638vw*0.1935;
    width: 100%;
    z-index: 0;
}

// #watermark {
//     position: absolute;
//     transform: translateY(-80%);
//     width: 1550px;
//     height: 300px;
//     z-index: 0;
// }
